import * as network from './network';

const fetchDashboardData = () => {
  return network.get('order/orderStats', {}, true);
};

const fetchSubsCount = () => {
  return network.get('subscription/subsCount', {}, true);
};

const fetchDuplicateSubs = () => {
  return network.get('subscription/duplicateSubs', {}, true);
};

const fetchSubscriptionStats = () => {
  return network.get('subscription/subscriptionStats', {}, true);
};

const fetchCouponStats = () => {
  return network.get('order/couponStats', {}, true);
};


export default {
  fetchDashboardData,
  fetchSubsCount,
  fetchDuplicateSubs,
  fetchSubscriptionStats,
  fetchCouponStats
};
