import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CategorySection from '../components/CategoryPage/CategorySection';

const CategoryManagement = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Category Management</h3>
                </div>
                <div className="middle_main">
                  {/* Nevigate to category page or sub category page using this section */}
                  <div className="row">
                    <div className="d-flex px-4 justify-content-center">
                      <div className="text-uppercase">
                        <div className="my-3 category_options">
                          <span
                            className={`position-relative fw-300 w-100 text-primary`}
                            onClick={() => {
                              navigate('/category');
                            }}
                          >
                            Category
                          </span>
                          <span className="mx-3 text-secondary">|</span>
                          <span
                            className={`position-relative fw-300 w-100 cursor-pointer text-secondary`}
                            onClick={() => {
                              navigate('/subcategory');
                            }}
                          >
                            Sub Category
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <CategorySection />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryManagement;
