import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import * as Yup from 'yup';
import moment from 'moment';
import ReactTable from './../common/UI/ReactTable';
import Loader from './../common/UI/Loader';
import SearchAndButtons from './../common/UI/Search&Buttons';
import AddCouponcodeForm from '../components/Couponcode/addCouponcodeForm';
import api from '../api/api';
import {
  getErrorMessage,
  getLabelValuePair,
  CouponTypeByKey,
} from '../utils/helpers';
import { toastSuccess } from '../common/UI/Toasts';
import editIcon from '../assets/images/pencil.svg';
import deleteIcon from '../assets/images/trash.svg';
import DeleteConfirmationModal from '../common/UI/CustomModal/deleteModal';
import { toastError } from './../common/UI/Toasts/index';

const Couponcode = () => {
  const COUPON_TYPES = [
    {
      label: 'General Promo Code',
      value: 'general_promo_code',
    },
    {
      label: 'Brand Promo Code',
      value: 'brand_promo_code',
    },
    {
      label: 'One Time Purchase Code',
      value: 'one_time_code',
    },
  ];
  const columns = [
    {
      Header: 'Coupon Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.code}</span>;
      },
    },
    {
      Header: 'Percentage Discount',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.percentage.toFixed(2)}%</span>;
      },
    },
    {
      Header: 'Coupon Type',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>
            {CouponTypeByKey[row.original.typeof_coupon]}
            <br />
            {row.original.typeof_coupon === 'brand_promo_code'
              ? `(${row.original.brand.name})`
              : null}
          </span>
        );
      },
    },
    {
      Header: 'Start Date (YYYY-MM-DD)',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return moment(row.original.start_date).format('YYYY-MM-DD');
      },
    },
    {
      Header: 'End Date (YYYY-MM-DD)',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return moment(row.original.end_date).format('YYYY-MM-DD');
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span
              className="me-2"
              style={{ cursor: 'pointer' }}
              onClick={() => handleEdit(row.original)}
            >
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleDelete(row.original)}
            >
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];
  const initialValues = {
    coupon: '',
    cdiscount: '',
    start_date: '',
    end_date: '',
    ctype: null,
    brand: null,
    sub_categories: []
  };

  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCouponForm, setShowCouponForm] = useState(false);
  const [selectedOptionValue, setSelectedOptionValue] = useState([]);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [loadInitialValues, setLoadInitialValues] = useState(null);
  const [delModelShow, setDelModelShow] = useState(false);
  const [deleteCouponcodeName, setDeleteCouponcodeName] = useState(null);
  const [deleteCouponcodeId, setDeleteCouponcodeId] = useState(null);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    getAllBrand();
    getAllSubCategories();
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllCouponcodes();
  }, [currentPageNo, seeAll, showCouponForm, delModelShow]);

  const validationSchema = Yup.object({
    coupon: Yup.string().required(
      getErrorMessage('required', 'Couponcode Name'),
    ),
    cdiscount: Yup.number()
      .required(getErrorMessage('required', 'Percentage Discount'))
      .min(0, 'Percentage discount must be greater than or equal to 0')
      .max(100, 'Percentage discount must be less than or equal to 100'),
    start_date: Yup.date()
      .nullable()
      .required(getErrorMessage('select-required', 'Start date')),
    end_date: Yup.date()
      .nullable()
      .required(getErrorMessage('select-required', 'End date')),
    ctype: Yup.object()
      .nullable()
      .required(getErrorMessage('select-required', 'Coupon Type')),
    brand: Yup.object()
      .nullable()
      .when('ctype', {
        is: (type) => type?.value === 'brand_promo_code',
        then: Yup.object()
          .nullable()
          .required(getErrorMessage('select-required', 'Brand')),
      }),
  });

  const onSubmit = (values, onSubmitProps) => {
    onSubmitProps.setSubmitting(true);
    if (loadInitialValues?.id) {
      const payload = {
        code: values?.coupon,
        percentage: parseFloat(values?.cdiscount)?.toFixed(2),
        typeof_coupon: values?.ctype?.value,
        start_date: moment(values?.start_date).format('YYYY-MM-DD'),
        end_date: moment(values?.end_date).format('YYYY-MM-DD'),
      };
      const bpayload =
        values?.ctype?.value === 'brand_promo_code'
          ? {
              brand: {
                id: values?.brand?.value,
                name: values?.brand?.label,
              },
            }
          : null;

          const onetimepayload = values?.ctype?.value === 'one_time_code' && values?.sub_categories?.length > 0
          ? {
              sub_categories: values?.sub_categories
            }
          : null;
          
      api
        .updateCouponcode(loadInitialValues.id, { ...payload, ...bpayload, ...onetimepayload })
        .then(() => {
          onSubmitProps.resetForm();
          onSubmitProps.setSubmitting(false);
          handleAddCouponcode(false);
          toastSuccess('Couponcode Updated Successfully');
        })
        .catch((err) => {
          onSubmitProps.setSubmitting(false);
          console.log('error', err);
        });
    } else {
      const payload = {
        code: values?.coupon,
        percentage: values?.cdiscount?.toFixed(2),
        typeof_coupon: values?.ctype?.value,
        start_date: moment(values?.start_date).format('YYYY-MM-DD'),
        end_date: moment(values?.end_date).format('YYYY-MM-DD'),
      };
      const bpayload =
        values?.ctype?.value === 'brand_promo_code'
          ? {
              brand: {
                id: values?.brand?.value,
                name: values?.brand?.label,
              },
            }
          : null;
          
          const onetimepayload = values?.ctype?.value === 'one_time_code' && values?.sub_categories?.length > 0
          ? {
              sub_categories: values?.sub_categories
            }
          : null;
      api
        .addCouponcode({ ...payload, ...bpayload, ...onetimepayload })
        .then(() => {
          onSubmitProps.resetForm();
          onSubmitProps.setSubmitting(false);
          handleAddCouponcode(false);
          toastSuccess('Couponcode Added Successfully');
        })
        .catch((err) => {
          onSubmitProps.setSubmitting(false);
          console.log('error', err);
          if (err?.message == 'Coupon already exist') {
            toastError('This Couponcode already exist');
          }
        });
    }
  };

  const handleEdit = (couponData) => {
    handleAddCouponcode(true);
    setLoadInitialValues({
      id: couponData?._id,
      coupon: couponData?.code,
      cdiscount: couponData?.percentage.toFixed(2),
      start_date: new Date(couponData?.start_date),
      end_date: new Date(couponData?.end_date),
      ctype: {
        label: CouponTypeByKey[couponData?.typeof_coupon],
        value: couponData?.typeof_coupon,
      },
      brand:
        couponData?.typeof_coupon === 'brand_promo_code'
          ? {
              label: couponData?.brand?.name,
              value: couponData?.brand?.id,
            }
          : null,
      sub_categories:  
        couponData?.typeof_coupon === 'one_time_code'
      ? couponData?.sub_categories
      : null,
    });    
  };

  const handleAddCouponcode = (isShow) => {
    setLoadInitialValues(null);
    setShowCouponForm(isShow);
  };

  const handleDelete = (couponData) => {
    setDelModelShow(true);
    setDeleteCouponcodeName(couponData?.name);
    setDeleteCouponcodeId(couponData?._id);
  };

  const deleteModalOnHide = () => {
    setDelModelShow(false);
    setDeleteCouponcodeId(null);
    setDeleteCouponcodeName(null);
  };

  const deleteModalOnDelete = () => {
    api
      .deleteCouponcode(deleteCouponcodeId)
      .then(() => {
        toastSuccess('Couponcode Deleted Successfully');
        setDeleteCouponcodeId(null);
        setDeleteCouponcodeName(null);
        setDelModelShow(false);
      })
      .catch((err) => {
        console.log('error', err);
        setDeleteCouponcodeId(null);
        setDeleteCouponcodeName(null);
      });
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getAllBrand = () => {
    const params = { perPage: 20, page: 1 };
    api
      .getAllBrands(params)
      .then((res) => {
        const response = getLabelValuePair(res.data?.data?.docs, 'name', '_id');
        setSelectedOptionValue(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSubCategories = () => {
    api
      .getAllSubcategory({ showall: true })
      .then((res) => {
        console.log(res, 'resss');
        
        const response = getLabelValuePair(res.data?.data?.docs, 'name', '_id');
        setSubCategoriesOptions(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCouponcodes = (isSearch = true) => {
    let params = seeAll
      ? { showall: true }
      : { perPage: 10, page: currentPageNo };
    if (isSearch && searchVal) params.search = searchVal;
    api
      .getAllCouponcodes(params)
      .then((res) => {
        setData(res.data?.data?.docs);
        setTotalPage(res.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const searchCouponcode = (searchkey) => {
    setSearchVal(searchkey);
    if (searchkey) {
      const params = seeAll
        ? { showall: true, search: searchkey }
        : { search: searchkey, perPage: 10, page: currentPageNo };
      api
        .searchCouponcode(params)
        .then((res) => {
          setData(res.data?.data?.docs);
          setTotalPage(res.data?.data?.totalPages);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      getAllCouponcodes(false);
    }
  };

  const getAsyncBrands = async (params) => {
    try {
      let brandParams = { perPage: 20, page: 1, ...params };
      const response = await api.getBrandsOnSearch(brandParams);
      let brandData = response?.data?.data;
      return getLabelValuePair(brandData, 'name', '_id');
    } catch (err) {
      console.log(err);
    }
  };

  const getAsyncOptions = (inputValue, fieldName) => {
    if (fieldName === 'brand') {
      return getAsyncBrands({ search: inputValue });
    }
  };

  const loadOptions = (inputValue, callback, linkedOption) => {
    getAsyncOptions(inputValue, linkedOption).then((results) =>
      callback(results),
    );
    return;
  };

  const debouncedLoadOptions = debounce(loadOptions, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  return (
    <div className="content_wrapper all_products_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              {showCouponForm ? (
                <>
                  <div className="header_top">
                    <h3>
                      {loadInitialValues && loadInitialValues.id
                        ? 'Edit'
                        : 'Add'}{' '}
                      Coupon Code
                    </h3>
                  </div>
                  <div className="middle_main">
                    <AddCouponcodeForm
                      selectedOptionValue={selectedOptionValue}
                      debouncedLoadOptions={debouncedLoadOptions}
                      initialValues={loadInitialValues || initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      handleAddCouponcode={handleAddCouponcode}
                      COUPON_TYPES={COUPON_TYPES}
                      subCategoriesOptions={subCategoriesOptions}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="header_top">
                    <h3>Coupon Code</h3>
                  </div>
                  <div className="middle_main">
                    <SearchAndButtons
                      onSearchChange={searchCouponcode}
                      onButtonClick={() => handleAddCouponcode(true)}
                      searchPlaceholder={'Search by coupon...'}
                      buttonName={'Add Couponcode'}
                      seeAllHandle={seeAllHandle}
                    />
                    {loading ? (
                      <Loader loading={loading} />
                    ) : (
                      <ReactTable
                        title="Coupon Code"
                        tableColumns={columns}
                        tableData={data}
                        seeAll={seeAll}
                        nextHandle={nextHandle}
                        prevHandle={prevHandle}
                        totalPage={totalPage}
                        pageNo={currentPageNo}
                      />
                    )}
                  </div>
                  <DeleteConfirmationModal
                    name={deleteCouponcodeName}
                    module={'Coupon Code'}
                    show={delModelShow}
                    handleToggle={deleteModalOnHide}
                    handleDelete={deleteModalOnDelete}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Couponcode;
