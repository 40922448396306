import { range } from 'lodash';
import React, { useContext, useMemo, useEffect } from 'react';
import { Routes } from 'react-router-dom';
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useAsyncDebounce,
  useResizeColumns,
} from 'react-table';
import sortIcon from '../../../assets/images/caret-up.svg';
import leftArrow from '../../../assets/images/left_arrow.svg';
import rightArrow from '../../../assets/images/right_arrow.svg';
import Loader from '../Loader';
import {
  getFilterParams,
  getSortingParams,
  renderSortingArrows,
} from './tableHelper';

const filterContext = React.createContext({
  filters: null,
});
const ReactTable = (props) => {
  const {
    tableColumns,
    tableData,
    seeAll = false,
    nextHandle,
    prevHandle,
    totalPage,
    pageNo,
    onChangeSort = () => {},
    groupCols = false,
    initialSortBy = [],
    initialFilters = [],
    manualFilters,
    tbody_classname,
    showConditional,
    isBottomPadding,
    loading,
  } = props;
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);
  const context = useContext(filterContext);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: <div></div>,
    }),
    [],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    state: { sortBy, filters, pageIndex, pageSize },
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      manualSortBy: true,
      disableMultiSort: true,
      manualPagination: true,
      manualFilters: manualFilters || false,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        sortBy: initialSortBy || [],
        filters: initialFilters || [],
        pageSize: 10,
      },
    },
    useResizeColumns,
    useFilters,
    useSortBy,
    usePagination,
  );
  const onFetchDataDebounced = useAsyncDebounce(onChangeSort, 500);

  useEffect(() => {
    if (sortBy && sortBy.length > 0 && filters && filters.length > 0) {
      onFetchDataDebounced(
        {
          ...getSortingParams(sortBy),
          ...getFilterParams(filters),
        },
        pageIndex,
        pageSize,
      );
    } else if (sortBy && sortBy.length > 0) {
      onFetchDataDebounced(
        { ...getSortingParams(sortBy) },
        pageIndex,
        pageSize,
      );
    } else if (filters && filters.length > 0) {
      let isFilterEdit = false;
      if (JSON.stringify(context.filters) !== JSON.stringify(filters)) {
        isFilterEdit = true;
        context.filters = filters;
        gotoPage(0);
      }
      onFetchDataDebounced(
        { ...getFilterParams(filters) },
        isFilterEdit ? 0 : pageIndex,
        pageSize,
      );
    } else onFetchDataDebounced({}, pageIndex, pageSize);
  }, [filters]);
  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-0 mt-4">
          <div
            className="table-responsive products_table"
            style={{ paddingBottom: isBottomPadding ? 200 : 0 }}
          >
            <table className="table table-borderless mb-3" {...getTableProps()}>
              <thead className="products_table__thead">
                {headerGroups?.map((headerGroup, headerId) => {
                  return (
                    <tr key={headerId} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers?.map((column, headerCloumnId) => {
                        return (
                          <th
                            {...column.getHeaderProps({
                              style: {
                                minWidth: column.minWidth,
                                width: column.width,
                              },
                            })}
                            className="products_table__theading"
                            scope="col"
                            key={headerCloumnId}
                            title={column.Header}
                          >
                            {showConditional ? (
                              column.canFilter ? (
                                column.render('Filter')
                              ) : null
                            ) : (
                              <>
                                <span className="d-flex justify-content-between">
                                  {column.render('Header')}
                                  <span
                                    className="sort-arrow-container"
                                    title="Toggle SortBy"
                                  >
                                    {renderSortingArrows(column)}
                                  </span>
                                </span>
                                {column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              {!loading ? (
                <tbody
                  className={`products_table__tbody `}
                  {...getTableBodyProps()}
                >
                  {rows.length ? (
                    rows?.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr
                          className="p-0 tbody_classname"
                          key={i}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell, i) => {
                            return (
                              <td
                                key={i}
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                  },
                                })}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={columns.length + 4} className="text-center">
                        No data found!
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : null}
            </table>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader
          loading={loading}
          hideLoadingMessage={false}
          loaderMarginTop={'90px'}
          loaderMarginBottom={'96px'}
        />
      ) : null}
      {!seeAll && (
        <div className="row">
          <div className="pagination justify-content-center">
            <div className="pagination__inner">
              <div className="pagination__btn previous ">
                <span>
                  <button
                    onClick={() => prevHandle()}
                    disabled={pageNo == 1 ? true : false}
                  >
                    <img
                      src={leftArrow}
                      alt="left arrow"
                      width="21"
                      height="21"
                    />
                  </button>
                </span>
              </div>
              {!seeAll && (
                <div>
                  <strong>{pageNo}</strong> of <strong>{totalPage}</strong>
                </div>
              )}
              <div className="pagination__btn  next">
                <span>
                  <button
                    onClick={() => nextHandle()}
                    disabled={pageNo == totalPage ? true : false}
                  >
                    <img
                      src={rightArrow}
                      alt="right arrow"
                      width="21"
                      height="21"
                    />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReactTable;
